<template>
  <div v-auto-animate>
    <section class="bf-container flex-col md:flex-row">
      <!-- <section class="bf-container flex-col md:flex-row"> -->
      <div class="bf-section bf-section-col flex">
        <div
          id="bf-section-1"
          class="bf-section min-h-[234px] lg:min-h-[360px] bg-special-black-friday-black lg:items-center flex cursor-pointer lg:cursor-default"
          @click="handleClick"
        >
          <img
            :src="blackFridayLogo"
            class="max-w-[174px] lg:max-w-[248px]"
            alt="Para quem chega antes. Black Friday Eletromidia"
          />
        </div>

        <div
          id="bf-section-2"
          class="bf-section bf-section-col px-[66px] !hidden lg:!flex lg:min-h-[360px] cursor-pointer lg:cursor-default"
          @click="handleClick"
        >
          <span class="text-[1.5rem]">Todo o site com até</span>
          <span class="text-[6rem]">30%<br />OFF</span>
          <Button label="garantir meu desconto" @click.stop="handleClick" />
        </div>
      </div>

      <div id="bf-section-3" class="bf-section !hidden md:!flex">
        <!-- <img class="w-full h-full object-cover" :src="stationBillboard" alt="" /> -->
        <video
          autoplay
          muted
          loop
          playsInline
          class="w-full h-full object-cover"
          width="100%"
          height="100%"
          :poster="blackFridayVideo.media.thumb"
          :src="blackFridayVideo.media.path.desktop"
        ></video>
      </div>

      <div id="bf-section-4_container" class="bf-section bf-section-col relative" @click="handleClick">
        <div id="bf-section-4_1" class="bf-section py-[30.5px]">
          <div
            id="cart-discount-top"
            class="font-bold px-12 md:px-16 lg:px-20 text-2xl leading-[25px] tracking-[0.02em] lg:text-6xl lg:leading-[42px] lg:tracking-[0.01em]"
          >
            Faça seu primeiro anúncio na Eletromidia com até 30% de desconto durante a Black Friday
          </div>
        </div>
        <div id="bf-section-4_2" class="bf-section min-h-[198px] items-center">
          <img
            class="absolute left-1/2 transform -translate-x-1/2 bottom-0 z-1 max-w-[247px] lg:max-w-[429px]"
            alt=""
            :src="girlSmiling"
          />
          <Button
            class="absolute left-1/2 transform -translate-x-1/2 z-[2] bottom-[32px] lg:hidden !h-[41px] !bg-white !text-black text-nowrap"
            label="garantir meu desconto"
            icon="uil-arrow-right"
            iconPos="right"
            outlined
            @click.stop="handleClick"
          />
        </div>
      </div>
    </section>
    <div
      id="bf-footer"
      class="text-left sm:text-center px-6 sm:px-20 py-4 sm:py-5 text-sm sm:text-base text-white bg-surface-800"
    >
      Esta oferta é válida exclusivamente para a primeira compra, limitada a uma por CPF/CNPJ. Não pode ser combinada
      com outras promoções ou cupons, exceto para lojistas ou moradores de condomínios com telas da Eletromidia.
      Promoção válida para compras feitas exclusivamente pela plataforma Eletromidia Aqui.
    </div>
  </div>
</template>

<script setup>
import { useIsMobile } from "@/hooks";
import { rotaResultados } from "@/routes/index";

const { isMobile } = useIsMobile();
const blackFridayLogo =
  "https://storage.googleapis.com/elt-smb-prod/assets/campaigns/2024/black-friday/black-friday-elt-white.png";
const girlSmiling = "https://storage.googleapis.com/elt-smb-prod/assets/campaigns/2024/black-friday/girl-smiling.webp";
const stationBillboard =
  "https://storage.googleapis.com/elt-smb-prod/assets/campaigns/2024/black-friday/station-billboard.webp";

const blackFridayVideo = {
  media: {
    thumb: "https://storage.googleapis.com/elt-smb-prod/assets/campaigns/2024/black-friday/home-bf-thumb.jpg",
    path: {
      desktop: "https://storage.googleapis.com/elt-smb-prod/assets/campaigns/2024/black-friday/home-bf-ffmpeg.mp4",
    },
  },
};

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
});

const handleClick = (event) => {
  if (isMobile.value) {
    window.location.href = rotaResultados.url;
  } else {
    if (event.target.closest("button")) {
      window.location.href = rotaResultados.url;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/themes/tailwind/tailwind-light/_variables";

.bf-container {
  display: flex;
  //flex-direction: row;
}

.bf-section {
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  margin: 0 auto;
}

.bf-section-col {
  display: flex;
  flex-direction: column;
}

#bf-section-1 {
}
#bf-section-2 {
  & span {
    line-height: 100%;
    color: $primaryColor;
    font-weight: bold;
  }
}
#bf-section-3 {
}
#bf-section-4_container {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;

  #cart-discount-top {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #bf-section-4_1 {
    background-color: #f8c4ac;
  }

  #bf-section-4_2 {
    background-color: #ff4f00;
  }
}
#bf-section-5 {
  grid-area: 3 / 1 / 4 / 4;
}

.section-square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-ret {
}

#station-billboard {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
